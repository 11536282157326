import config from '../config/index'
import { mainRequests } from './request'
// import axios from 'axios'
// function handleResponse (response) {
//   if (response.status === 401) {
//     // signOut()
//     // timedRefresh()
//   }
//   return response.data
// }
export default {
  // login(payload) {
  //   const promise = new Promise((resolve, reject) => {
  //     axios.post(config.BASE_LOGIN, payload)
  //       .then(handleResponse)
  //       .then(data => {
  //         resolve(data)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  //   return promise
  // },
  driverOrdersHistory(body){
    return new Promise(function (resolve, reject){
      mainRequests
        .customPost(config.BASE_GET_DRIVER_HISTORY, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
            reject(err)
        })
    })
  },
  driverOutByDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost( config.BASE_DRIVER_OUT_DRIVER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  driverInByDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost( config.BASE_DRIVER_INBY_DRIVER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
            reject(err)
        })
    })
  },
  orderCompleteByDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_ORDER_COMPLETE_DRIVER, body)
        .then(res => {
            resolve(res)
        })
        .catch(err => {
            reject(err)
        })
    })
  },
}
