import { Auth } from 'aws-amplify'
// import { AmplifyEventBus } from 'aws-amplify-vue'

function getUser () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        return user
      } else {
        return null
      }
    })
    .catch(err => {
      console.log(err)
    })
}

function getUserName () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        return user.attributes.email
      } else {
        return null
      }
    })
    .catch(err => {
      console.log(err, 'error')
    })
}

function getSupplierId () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        const tempResponse = user.attributes['custom:supplier_ids']
        const supplierId = JSON.parse(tempResponse)
        return supplierId
      } else {
        return null
      }
    })
    .catch(err => {
      console.log(err, 'error')
    })
}
async function getToken () {
  return await Auth.currentSession()
    .then(session => {
      if (session) {
        return session.idToken.jwtToken
      } else {
        return null
      }
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    })
}

function signUp (username, email, password, nickname, birthdate, phone_number, regNo) {
  return Auth.signUp({
    username,
    password,
    attributes: {
      'custom:status': 'inactive',
      'custom:regNo': regNo,
      name: username,
      email: email,
      nickname: nickname,
      birthdate: birthdate,
      phone_number: phone_number
    }
  })
    .then(data => {
    //   AmplifyEventBus.$emit('localUser', data.user)
      if (data.userConfirmed === false) {
        console.log('Хэрэглэгчийг баталгаажуулаагүй байна. Админтай холбогдоно уу!', 'error')
        // AmplifyEventBus.$emit('authState', 'signIn')
      } else {
        // AmplifyEventBus.$emit('authState', 'signedIn')
      }
      return data
    })
    .catch(err => {
      userErrorMessageHandler(err)
    })
}

function confirmSignUp (username, code) {
  return Auth.confirmSignUp(username, code)
    .then(data => {
    //   AmplifyEventBus.$emit('authState', 'signIn')
      return data // 'SUCCESS'
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    })
}

async function signIn (username, password) {
  try {
    const user = await Auth.signIn(username, password)
    if (user) {
    //   AmplifyEventBus.$emit('authState', 'signedIn')
    }
  } catch (err) {
    userErrorMessageHandler(err)
  }
}

function userErrorMessageHandler (err) {
  if (err.code === 'UserNotConfirmedException') {
    throw new Error('Хэрэглэгчийг баталгаажуулаагүй байна. Админтай холбогдоно уу!')
  } else if (err.code === 'PasswordResetRequiredException') {
    throw new Error('Хэрэглэгчийг нууц үгийг сэргээх шаардлагтай.!')
  } else if (err.code === 'NotAuthorizedException') {
    throw new Error('Хэрэглэгчийн нууц үг эсвэл имайл буруу байна.!!')
  } else if (err.code === 'UserNotFoundException') {
    throw new Error('Хэрэглэгчийн нууц үг эсвэл имайл буруу байна.!!')
  } else if (err.code === 'InvalidParameterException') {
    throw new Error(err.message)
  } else {
    throw new Error('Алдаа гарлаа.!!')
  }
}

function signOut () {
  return Auth.signOut()
    .then(data => {
    //   AmplifyEventBus.$emit('authState', 'signedOut')
      return data
    })
    .catch(err => {
      console.log(err, 'error')
    })
}

export {
  getUser,
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  getToken,
  getUserName,
  getSupplierId
}
