<template>
  <ion-menu menu-id="app-menu" content-id="main-content">    
    <ion-content class="menu">
    <div class="container">
      <div>
        <ion-img :src="require('../img/toktokmall.png')" class="image"/>
      </div>
      <div class="menuContainer">
        <div class="menuLabel" @click="onPressTab(tabData.route)" v-for="(tabData, index) in tabDatas" :key="index" >
          <ion-icon :icon="tabData.icon"></ion-icon><ion-text class="text">{{tabData.name}}</ion-text>
        </div>
        <div class="container">
          <span  class="hehe" >{{userName}}</span>
        </div>
        <div class="container">
          <ion-button size="small" color="danger" @click="logOut()">Гарах</ion-button>
        </div>
      </div>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script>
import { getUserName, signOut } from '../utils/auth.js'
import {
  IonMenu,IonContent,menuController, IonText, IonIcon, IonImg,
} from "@ionic/vue";
import {
  notifications,
  restaurant,
  pricetag,
  bagHandle,
  statsChart,
  map
} from "ionicons/icons";
export default {
  components: {
    IonMenu,IonContent, IonText, IonIcon, IonImg,
  },
  setup() {
    return {
      notifications,
      restaurant,
      bagHandle,
      statsChart,
      pricetag,
    };
  },
  data () {
    return {
      userName: ''
    }
  },
  created () {
    getUserName().then(email => {
      this.userName = email
    })
  },
  computed: {
    tabDatas(){
      const returnData = [
        {name: "Захиалга", icon: bagHandle, route: 'Order'},
        {name: "Хүргэлтийн түүх", icon: statsChart, route: 'OrderHistory'},
        {name: "Татан авалт", icon: pricetag, route: 'Transfer'},
        {name: "Байршил", icon: map, route: 'Location'},
      ]
      return returnData;
    }
  },
  methods:{
    onPressTab(route) {
      menuController.close("app-menu")
      this.$router.push({name:route})
    },
    logOut(){
      signOut()
      menuController.close("app-menu")
      this.$router.push('/login')
    },
  },
};

</script>
<style scoped>
.hehe{
  margin-top:100%;
  padding-bottom:10px;
  font-family:'Times New Roman', Times, serif;
  font-size: 20px;
  
}
.container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.menuContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.image{
  width: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.text{
  padding-left: 10px;
  font-family: 'Mulish-Regular';
  font-size: 16px;
}
.text:active{
  border-radius: 25px;
  border: 2px solid #73AD21;
}
.text:hover {
   opacity: 1;
   transition: all 0.2s ease-out;
   color: rgb(255,100,100);
 }
.menuLabel{
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.menuLabelActive{
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.logout{
  margin-top: 120%;
  display: flex;
  align-items: center;
  
}
/* .logOutLabel {
  padding: 60px;
  color: rgb(223, 61, 61);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 150px;
} */
</style>