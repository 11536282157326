<template>
  <ion-page>
      <ion-modal
        :is-open="isOpenRef"
        css-class="my-custom-class"
        @didDismiss="true"
      >
        <Modal @closeModal="closeModal()"></Modal>
      </ion-modal>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          refreshing-spinner="circles"
          refreshing-text="Уншиж байна"
        >
        </ion-refresher-content>
      </ion-refresher>
      <div>
        <ion-grid>
          <ion-row>
            <ion-col
              size-xs="2"
              size-sm="2"
              size-md="2"
              size-lg="2"
              size-xl="2"
            >
              <ion-menu-button
                auto-hide="false"
                @click="openMenu()"
                menu="menu"
                style="color: green; font-size: 25px"
              ></ion-menu-button>
            </ion-col>
            <div style="display:flex">

            <div @click="onPressTab(tabData.route)" v-for="(tabData, index) in tabDatas" :key="index" style="margin: 0.5rem 0 0 0">
            <ion-col
            >
              <ion-text class="center" style="font-size:17px; font-family: 'Mulish-Regular'; padding: 1rem;">{{tabData.name}}</ion-text>
            </ion-col>
            </div>
            </div>
            <ion-button v-model="activeStatus" shape="round" :color="activeStatus ? 'success' : 'danger'" size="small" class="driver-button" @click="onPressStatus">
            <logo-models-icon class="ion-icon20"></logo-models-icon>
            </ion-button>
            <ion-col
              size-xs="8"
              size-sm="8"
              size-md="8"
              size-lg="8"
              size-xl="8"
            >
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div v-if="orders.length > 0" class="cardRow">
        <ion-card
          @click="pushOrderDetail(value)"
          v-for="value in orders"
          :key="value"
          class="cardOrder"
        >
          <ion-grid>
            <ion-row>
              <ion-col
                size-xs="4"
                size-sm="4"
                size-md="3"
                size-lg="4"
                size-xl="4"
              >
                <ion-img :src="require('../../img/delivery.png')" />
              </ion-col>
              <ion-col
                size-xs="8"
                size-sm="8"
                size-md="9"
                size-lg="8"
                size-xl="8"
              >
                <ion-row>
                  <ion-col
                    size-xs="7"
                    size-sm="8"
                    size-md="8"
                    size-lg="8"
                    size-xl="8"
                    class="txt"
                  >
                    <ion-text>З/Дугаар: {{ value.order_number }}</ion-text>
                  </ion-col>
                  <ion-col
                    class="pay"
                    size-xs="5"
                    size-sm="4"
                    size-md="4"
                    size-lg="4"
                    size-xl="3"
                    color="danger"
                  >
                    <ion-text
                      v-if="value.pay_status === 'Төлөөгүй'"
                      color="danger"
                    >
                      {{ value.pay_status }}</ion-text
                    >
                    <ion-text
                      v-if="value.pay_status === 'Төлсөн'"
                      color="success"
                    >
                      {{ value.pay_status }}</ion-text
                    >
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="txt">
                    {{ formatDate(value.driver_assigned) }}
                  </ion-col>
                </ion-row>
                <ion-row style="color: grey" class="address">
                  <ion-col>
                    <ion-button
                      color="success"
                      expand="block"
                      size="small"
                      fill="outline"
                      >Дэлгэрэнгүй</ion-button
                    >
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
      <div v-else>
        <p class="text-center" style="opacity: 0.5; text-align: center">
          Захиалга байхгүй байна
        </p>
      </div>
      <div class="footer">
        <ion-grid>
          <ion-row>
            
            <ion-col
              size-xs="4"
              size-sm="4"
              size-md="4"
              size-lg="4"
              size-xl="4"
            >
              <ion-button
                expand="full"
                shape="round"
                color="success"
                @click="sortByOrderNumberDesc()"
                ><MdArrowDownIcon
                  style="fill: white; font-size: 20px"
                />З/Дугаар</ion-button
              >
            </ion-col>
            <ion-col
              size-xs="4"
              size-sm="4"
              size-md="4"
              size-lg="4"
              size-xl="4"
            >
              <ion-button
                expand="full"
                shape="round"
                color="success"
                @click="sortByOrderDateDesc()"
              >
                <MdCalendarIcon style="fill: white; font-size: 20px" />
                Огноо</ion-button
              >
            </ion-col>
            <ion-col
              size-xs="4"
              size-sm="4"
              size-md="4"
              size-lg="4"
              size-xl="4"
            >
              <ion-button
                expand="full"
                shape="round"
                color="success"
                @click="sortByOrderPositionDesc()"
                ><MdLocationIcon style="fill: white; font-size: 20px" /> <span style="font-size: 11px"> Надтай
                ойр</span></ion-button
              >
            </ion-col>
            <!-- <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4">
              <ion-button expand="full" shape="round" color="success" @click="sortByOrderNumberDesc"><MdArrowDownIcon style="fill:white; font-size:20px;"/>Байршил</ion-button >
            </ion-col> -->
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonModal,
  IonCol,
  IonRow,
  IonCard,
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonText,
  IonImg,
  IonMenuButton,
  menuController,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import { Auth } from 'aws-amplify'
import Modal from './component/Modal.vue'
import LogoModelsIcon from 'vue-ionicons/dist/logo-model-s.vue';
import { defineComponent } from "vue";
import axios from "axios";
import helper from "../../helpers/helper";
import MdLocationIcon from "vue-ionicons/dist/md-pin.vue"
import { getUser } from '../../utils/auth'
import MdArrowDownIcon from "vue-ionicons/dist/md-arrow-down.vue";
import MdCalendarIcon from "vue-ionicons/dist/md-calendar.vue";
// import geolocation from '@ionic-native/geolocation/ngx'
import { bagHandle } from "ionicons/icons";
function compareNumberDesc(a, b) {
  if (a.order_number < b.order_number) {
    return 1;
  }
  if (a.order_number > b.order_number) {
    return -1;
  }
  return 0;
}
function compareNumberAsc(a, b) {
  if (a.order_number < b.order_number) {
    return -1;
  }
  if (a.order_number > b.order_number) {
    return 1;
  }
  return 0;
}
function compareDateDesc(a, b) {
  if (a.driver_assigned < b.driver_assigned) {
    return 1;
  }
  if (a.driver_assigned > b.driver_assigned) {
    return -1;
  }
  return 0;
}
function compareDateAsc(a, b) {
  if (a.driver_assigned < b.driver_assigned) {
    return -1;
  }
  if (a.driver_assigned > b.driver_assigned) {
    return 1;
  }
  return 0;
}
function comparePositionDesc(a, b) {
  if (a.distance < b.distance) {
    return -1;
  }
  if (a.distance > b.distance) {
    return 1;
  }
  return 0;
}
function comparePositionAsc(a, b) {
  if (a.distance < b.distance) {
    return 1;
    }
    if (a.distance > b.distance) {
      return -1;
    }
    return 0;
  }

export default defineComponent({
  setup() {
    const doRefresh = (event) => {
      window.location.reload();
      event.target.complete();
    };
    return { doRefresh, bagHandle };
  },
  components: {
    LogoModelsIcon,
    IonImg,
    Modal,
    IonModal,
    IonButton,
    IonCol,
    IonRow,
    IonCard,
    IonContent,
    IonPage,
    IonGrid,
    IonText,
    IonMenuButton,
    MdLocationIcon,
    MdCalendarIcon,
    MdArrowDownIcon,
    IonRefresher,
    IonRefresherContent,
  },
  // mounted() {
  //   this.getOrders();
  // },
  computed: {
    tabDatas(){
      const returnData = [
        {name: "Захиалга", route: 'Order'},
        {name: "Байршил", route: 'Location'},
      ]
      return returnData;
    },
    isLogged() {
      return this.$store._state.data.isLogged;
    },
    computedOrders() {
      let tempOrders = JSON.parse(JSON.stringify(this.orders));
      if (this.positionLocation) {
        tempOrders.forEach((el, elIndex) => {
          tempOrders[elIndex].distance = helper.getDistanceFromLatLonInKm(
            parseFloat(this.positionLocation.lat),
            parseFloat(this.positionLocation.lng),
            parseFloat(el.lat),
            parseFloat(el.lng)
          );
        });
        tempOrders.sort((a, b) => {
          return a.distance - b.distance;
        });
      }
      return tempOrders;
    },
  },
  async mounted() {
    await this.userData()
    await this.getOrders()
    this.user = await Auth.currentAuthenticatedUser();
    this.activeStatus = await this.user.attributes["custom:status"] === 'active' ? true : false
    if (this.$store.state.closeModalDate !== new Date().getDate()) {
      this.isOpenRef = true
    }
  },
  async created () {
    await this.userData()
    await this.getOrders()
  },
  data() {
    return {
      isOpenRef: false,
      user: null,
      activeStatus: false,
      orders: [],
      sortType: true,
      userName: '',
      email: '',
      positionLocation: null,
      disable: false
    };
  },
  methods: {
    closeModal () {
      this.isOpenRef = false
      this.$store.commit('closeModalDates', new Date().getDate())
    },
    async onPressStatus() {
      this.activeStatus = !this.activeStatus
      let status = ''
      switch (this.activeStatus) {
        case true:
          status = 'active'
          break;
        case false:
          status = 'inactive'
          break;
        default:
          status = 'inactive'
      }
      await Auth.updateUserAttributes(this.user, {
        'custom:status': status
      })
    },
    onPressTab(route) {
      menuController.close("app-menu")
      this.$router.push({name:route})
    },
    openMenu() {
      menuController.open("app-menu");
    },
    formatDate(val) {
      return helper.formatDate(val);
    },
    async userData () {
      const response = await getUser()
      this.userName = response.username
    },
    sortByOrderNumberDesc() {
      this.orders.sort(this.sortType ? compareNumberDesc : compareNumberAsc);
      this.sortType = !this.sortType;
    },
    sortByOrderDateDesc() {
      this.orders.sort(this.sortType ? compareDateDesc : compareDateAsc);
      this.sortType = !this.sortType;
    },
    sortByOrderPositionDesc() {
      this.orders.sort(
        this.sortType ? comparePositionDesc : comparePositionAsc
      );
      this.sortType = !this.sortType;
    },
    async getOrders() {
      try {
        const orders = (
          await axios.get(
            "https://api.toktokmall.mn/api/driver/getOrdersByDriver?driver_name=" +
              this.userName
          )
        ).data;
        this.orders = orders.data;
        if (this.$root.currentLocation.lat) {
          this.orders.forEach((el, elIndex) => {
            this.orders[elIndex].distance = helper.getDistanceFromLatLonInKm(
              parseFloat(this.$root.currentLocation.lat),
              parseFloat(this.$root.currentLocation.lng),
              parseFloat(el.lat),
              parseFloat(el.lng)
            );
          });
        }
        // loading.dismiss();
        this.$forceUpdate();
      } catch (err) {
        console.log(err.message);
      }
    },
    pushOrderDetail(orderID) {
      this.$router.push({ name: "OrderInfo", params: { id: orderID.id } });
    },
  },
});
</script>
<style>
@media screen and (min-width: 1000px) {
  .address {
    width: 600px;
    font-size: 28px;
    color: grey;
  }
  .pay {
    font-size: 25px;
  }
  .txt {
    color: rgb(59, 56, 56);
    font-size: 40px;
    padding-top: 1%;
  }
  .cardOrder {
    height: 10%;
    width: 80%;
    font-family: "Mulish-Regular";
    margin-left: 10%;
  }
  .img {
    margin-left: 10%;
    width: 50%;
    height: 70%;
  }
}
@media screen and (min-width: 560px) and (max-width: 999px) {
  .txt {
    font-size: 30px;
    padding-top: 1%;
  }
  .cardOrder {
    height: 10%;
    font-family: "Mulish-Regular";
    font-size: 20px;
  }
  .img {
    margin-left: 10%;
    width: 60%;
    height: 95%;
  }
}
@media screen and (max-width: 550px) {
  .txt {
    font-size: 14px;
  }
  .cardOrder {
    font-family: "Mulish-Regular";
  }
  .address {
    width: 200px;
    font-size: 12px;
  }
  .img {
    margin-top: 5%;
    width: 100%;
    height: 100%;
  }
}
.cardRow {
  overflow-y: scroll;
}

.footer {
  background-color: rgb(248, 248, 248);
  position: fixed;
  bottom: 0;
  width: 100%;
}
.driver-button {
  margin-left: 10px;
  margin-top:5px
}

.ion-icon20 {
  font-size: 20px
}
</style>