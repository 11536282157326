<template>
  <div class="positionModal">
      <ion-title><h2>Автомашин шалгах хуудас</h2></ion-title>
      <ion-list>
        <form>
          <ion-item>
            <ion-checkbox v-model="tormoz" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Тормоз хэвийн
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="toli" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Толь хэвийн
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="dugui" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Дугуй хэвийн
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="gerel" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Гэрэл дохио хэвийн
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="hodolguur" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Хөдөлгүүр хэвийн
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="shil" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Шил, цонх хэвийн
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="suudliinBus" color="success"></ion-checkbox>
            <ion-label class="ml10">
              Суудлын бүс хэвийн
            </ion-label>
          </ion-item>
        </form>
      </ion-list>
      <ion-button expand="full" color="success" @click="closeModal()">Болсон</ion-button>
      <ion-toast
        :is-open="isOpenToast"
        message="Уучлаарай та дутуу шалгасан байна."
        :duration="2000"
        @didDismiss="this.isOpenToast = false"
      >
      </ion-toast>
  </div>
</template>

<script>
import { IonTitle, IonCheckbox, IonItem, IonLabel, IonList, IonButton, IonToast } from '@ionic/vue'
export default {
  data () {
    return {
      suudliinBus: false,
      isOpenToast: false,
      shil: false,
      hodolguur: false,
      gerel: false,
      dugui: false,
      toli: false,
      tormoz: false,
    }
  },
  methods: {
    closeModal () {
      if (this.suudliinBus && this.shil && this.hodolguur && this.gerel && this.dugui && this.toli && this.tormoz) {
        this.$store.commit('closeModalDates', new Date().getDate())
        this.$emit('closeModal')
      } else {
        this.isOpenToast = true
      }
    },
  },
    components: {
        IonTitle,
        IonToast,
        IonLabel,
        IonCheckbox,
        IonItem,
        IonList,
        IonButton
    },
    setup() {
        const form = [
      { val: 'Тормоз хэвийн', isChecked: false },
      { val: 'Толь хэвийн', isChecked: false },
      { val: 'Дугуй хэвийн', isChecked: false },
      { val: 'Гэрэл дохио хэвийн', isChecked: false },
      { val: 'Хөдөлгүүр хэвийн', isChecked: false },
      { val: 'Шил, цонх хэвийн', isChecked: false },
      { val: 'Суудлын бүс хэвийн', isChecked: false }
    ];
    
    return { form };
    }
}
</script>

<style>
.positionModal {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}
.ml10 {
  margin-left: 10px;
}
</style>