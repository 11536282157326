<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button default-href="/"></ion-back-button>
          <ion-title>Жолоочийн байршил</ion-title>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="text-center" v-if="loading">
        <ion-spinner name="lines" class="text-center mt20"></ion-spinner>
      </div>
      <GoogleMap
        api-key="AIzaSyA53ydQHJU3UyAPwxJ8RqQgAECe8dqyTyA"
        style="width: 100%; height: 100%"
        :center="center"
        :zoom="15"
        :mapTypeControl="false"
        :scaleControl="false"
        :zoomControl="false"
        :streetViewControl="false"
        :fullscreenControl="false"
        v-else
      >
        <Marker
          @click="pushOrder(x)"
          v-for="(x, index) in orders"
          :key="index"
          :options="{
            position: { lat: x.lat, lng: x.lng },
            label: { text: `${x.order_number}`, fontWeight: 'bold', color: 'blue' },
            icon: orange,
          }"
        />
        <Marker :options="{ position: this.center, icon: car }" />
      </GoogleMap>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonToolbar,
  IonButtons,
  IonHeader,
  IonContent,
  IonPage,
  IonTitle,
  IonBackButton,
  IonSpinner,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import { getUser } from "../utils/auth";
import axios from "axios";
// import helper from "../helpers/helper";
export default defineComponent({
  name: "OrderList",
  components: {
    IonToolbar,
    IonButtons,
    IonHeader,
    IonContent,
    IonPage,
    IonTitle,
    IonBackButton,
    GoogleMap,
    Marker,
    IonSpinner,
  },
  data() {
    return {
      userName: "",
      loading: false,
      center: { lat: 47.922168, lng: 106.858392 },
      orders: [],
      orange:
        "https://icons.iconarchive.com/icons/icons-land/vista-map-markers/48/Map-Marker-Marker-Outside-Pink-icon.png",
      yellow: "http://maps.google.com/mapfiles/kml/paddle/ylw-circle.png",
      // car: '../img/peopleLocation.png',
      car: "https://image.toktok.mn/human.png",
      iconColor: null,
      positionLocation: null,
      sort: null,
      email: "",
    };
  },
  async mounted() {
    await this.userData()
    await this.getOrders()
  },
  async created() {
    await this.userData();
    await this.getOrders();
  },
  computed: {
    isLogged() {
      return this.$store._state.data.isLogged;
    },
    // userData() {
    //   return this.$store._state.data.user;
    // },
  },
  methods: {
    pushOrder(orderID) {
      this.$router.push({ name: "OrderInfo", params: { id: orderID.id } });
    },
    async userData() {
      const response = await getUser();
      this.userName = response.username;
    },
    async getOrders() {
      try {
        await axios
          .get(
            "https://api.toktokmall.mn/api/driver/getOrdersByDriver?driver_name=" +
              this.userName
          )
          .then((response) => {
            if (response.data.statusCode === 200) {
              response.data.data.map((el) => {
                el.lat = parseFloat(el.lat);
                el.lng = parseFloat(el.lng);
                return el;
              });
              this.orders = response.data.data;
              if (this.$root.currentLocation.lat) {
                this.center = {
                  lat: this.$root.currentLocation.lat,
                  lng: this.$root.currentLocation.lng,
                };
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.$forceUpdate();
      } catch (err) {
        console.log(err.message);
      }
    },
  },
});
</script>
