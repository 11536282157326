const BASE_PATH = 'https://api.toktokmall.mn/api/driver'
const BASE_DRIVER_ASSIGN = '/driverAssign'
const BASE_DRIVER_OUT_DRIVER = '/driverOutByDriver'
const BASE_DRIVER_INBY_DRIVER = '/driverInByDriver'
const BASE_ORDER_COMPLETE_DRIVER = '/orderCompleteByDriver'
const BASE_GET_DRIVER_HISTORY = '/getDriverHistory'
const BASE_GET_ORDER = '/getOrdersByDriver'
export default {
  BASE_PATH: BASE_PATH,
  BASE_DRIVER_ASSIGN: BASE_DRIVER_ASSIGN,
  BASE_DRIVER_OUT_DRIVER: BASE_DRIVER_OUT_DRIVER,
  BASE_DRIVER_INBY_DRIVER: BASE_DRIVER_INBY_DRIVER,
  BASE_ORDER_COMPLETE_DRIVER: BASE_ORDER_COMPLETE_DRIVER,
  BASE_GET_DRIVER_HISTORY: BASE_GET_DRIVER_HISTORY,
  BASE_GET_ORDER: BASE_GET_ORDER
}
