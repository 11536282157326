<template >
  <ion-app>
    <ion-content>
      <Menu></Menu>
      <ion-router-outlet id="main-content" />
    </ion-content>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';
import Menu from "./views/Menu.vue"
export default defineComponent({
  name: 'App',
  data() {
    return {
      currentLocation: {
        lat: null,
        lng: null
      }
    }
  },
  mounted() {
    navigator.geolocation.watchPosition(position => {
      this.$root.currentLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    }, error => console.log(error),
    { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 })
    // this.getLocation()
    // setInterval(() => {
    //   this.getLocation()
    // }, 30000);
  },
  methods: {
    getLocation () {
      navigator.geolocation.getCurrentPosition(position => {
        this.$root.currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, error => console.log(error),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 })
    }
  },
  components: {
    IonApp,
    IonRouterOutlet,
    Menu,
    IonContent,
  },
});
</script>