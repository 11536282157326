import axios from "axios"
import config from "../config"
export const mainRequests = {
    customPost,
  }
  function handleResponse (response) {
    if (response.status !== 401) {
        return response.data
    }
  }
  async function customPost (url, payload) {
    const promise = await new Promise((resolve, reject) => {
        axios.post(config.BASE_PATH + url, payload)
        .then(handleResponse)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
            reject(error)
        })
    })
    return promise
  }
  
 
  
  