<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-grid>
          <ion-row>
            <ion-col
              size-xs="10"
              offset-xs="1"
              size-sm="10"
              offset-sm="1"
              size-md="8"
              offset-md="2"
              size-lg="6"
              offset-lg="3"
            >
              <ion-img id="img" :src="require('../img/toktokmall.png')" />
              <ion-item style="margin-top: 30px">
                <ion-input
                  inputmode="email"
                  autocomplete="off"
                  v-model="loginForm.email"
                  placeholder="Username"
                  aut
                ></ion-input>
              </ion-item>
              <ion-item style="margin-top: 5px">
                <ion-input
                  type="password"
                  autocomplete="off"
                  v-model="loginForm.password"
                  placeholder="Password"
                ></ion-input>
              </ion-item>
              <ion-button
                expand="block"
                style="margin-top: 20px"
                type="submit"
                @click="login()"
                color="success"
                >Нэвтрэх</ion-button
              >
              <ion-button
                expand="block"
                style="margin-top: 20px"
                type="submit"
                @click="signUpClick()"
                color="primary"
                >Бүртгүүлэх</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonImg,
  IonInput,
  IonButton,
  toastController,
  IonCol,
  IonRow,
  IonGrid,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { signIn } from '../utils/auth'
// import { FirebaseAuthentication } from '@ionic-native/firebase-authentication'
export default defineComponent({
  name: "Login",
  data() {
    return {
      loading: false,
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    IonImg,
    IonCol,
    IonRow,
    IonGrid,
    IonItem,
  },

  methods: {
    signUpClick () {
      this.$router.push({path: 'signUp'})
    },
    async login() {
      try {
        this.loading = true
        signIn(this.loginForm.email, this.loginForm.password).then(async (response) => {
          console.log(response)
          const toast = await toastController.create({
            header: "Амжилттай",
            duration: 2000,
            position: "bottom",
          });
          this.$router.push({path: 'order'})
          await toast.present();
          this.loading = false
        }).catch(async err=>{
          const toast = await toastController.create({
            header: "Алдаа",
            message: err.message,
            duration: 2000,
            position: "top",
          });
          await toast.present();
        })
      } catch (err) {
        const toast = await toastController.create({
          header: "Алдаа",
          message: err.message,
          duration: 2000,
          position: "top",
        });
        await toast.present();
      }
    },
  },
});
</script>

<style>
@media screen and (min-width: 999px) {
  #img {
    margin-top: 20%;
    height: 30%;
  }
}
@media screen and (min-width: 600px) and (max-width: 990px) {
  #img {
    height: 45%;
  }
}
@media screen and (max-width: 550px) {
  #img {
    height: 40%;
    width: 100%;
  }
}
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
input:-webkit-autofill {
  background-color: #000 !important;
}
</style>