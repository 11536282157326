<template>
  <ion-page>
    <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        refreshing-spinner="circles"
        refreshing-text="Уншиж байна">
      </ion-refresher-content>
    </ion-refresher>
      <div>
        <ion-grid>
          <ion-row style="font-size: 18px">
            <ion-col size-xs="2.5" size-sm="2.5" size-md="4" size-lg="5" size-xl="5" >
              <ion-text @click="$router.go(-1)"><MdArrowBackIcon /></ion-text>
            </ion-col>
            <ion-col size-xs="9" size-sm="9" size-md="8" size-lg="7" size-xl="7" >
              <ion-text>Захиалгын дэлгэрэнгүй</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div class="div">
        <ion-card class="crdTable" v-if="order">
          <ion-grid>
            <ion-row v-for="(tabData, index) in orderDatas" :key="index">
              <ion-col size-xs="6" size-sm="6" size-md="6" size-lg="6" size-xl="6" >
                {{ tabData.name }}
              </ion-col>
              <ion-col class="colinfo" size-xs="6" size-sm="6" size-md="6" size-lg="6" size-xl="6" >
                {{ tabData.orderItem }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>

        <ion-card class="crdTable" v-if="order">
          <ion-grid>
            <ion-row>
              <ion-col v-if="marker === true" class="map">
                <GoogleMap api-key="AIzaSyA53ydQHJU3UyAPwxJ8RqQgAECe8dqyTyA" style="width: 100%; height: 100%" :center="location" :zoom="15" gestureHandling="cooperative">
                  <Marker :options="{ position: location }" />
                </GoogleMap>
              </ion-col>
              <ion-col v-else class="map">
                <GoogleMap api-key="AIzaSyA53ydQHJU3UyAPwxJ8RqQgAECe8dqyTyA" style="width: 100%; height: 100%" :center="location" :zoom="15" gestureHandling="cooperative">
                  <Marker :options="{ position: location }" />
                </GoogleMap>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col v-if="marker === true" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" >
                <ion-button v-on:click="marker = false" color="success" expand="full" size="small" shape="round" >Харилцагчийн байршил</ion-button>
              </ion-col>
              <ion-col v-if="marker === false" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" >
                <ion-button v-on:click="marker = true" color="success" expand="full" size="small" shape="round" >Харилцагчийн байршил</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>

        <ion-card class="crdTable" v-for="productOrder in productOrder" :key="productOrder" >
          <ion-grid>
            <ion-row class="tdOrderInfo"> Бүтээгдэхүүн: </ion-row>
            <ion-row>
              <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" >
                <ion-img :src="productOrder.image"> </ion-img>
              </ion-col>
              <ion-col size-xs="7" size-sm="7" size-md="7" size-lg="7" size-xl="7" >
                <ion-row class="tdOrderInfo"> Брэнд: {{ productOrder.brand_name }}</ion-row>
                <ion-row class="tdOrderInfo" v-if="productOrder.variant_name !== 'default'"> Нэр: {{ productOrder.product_name }} - {{ productOrder.variant_name }}</ion-row>
                <ion-row class="tdOrderInfo" v-else> Нэр: {{ productOrder.product_name }}</ion-row>
                <ion-row class="tdOrderInfo"> Тоо: {{ productOrder.item_count }}</ion-row>
                <ion-row class="tdOrderInfo"> Үнэ: {{ productOrder.menu_price }}</ion-row>
                <ion-row class="tdOrderInfo"> SKU: {{ productOrder.sku }}</ion-row>
                <ion-row class="tdOrderInfo"> Харилцагчийн SKU: {{ productOrder.supplier_sku }}</ion-row>
              </ion-col>
            </ion-row>
            <ion-row>
            <ion-col>Нийлүүлэгчийн утас:</ion-col>
            <ion-col class="colinfo"> {{productOrder.supplier_phone1}}</ion-col>
            </ion-row>
            <ion-row>
            <ion-col> Н/Хаяг:</ion-col>
            <ion-col class="colinfo">{{productOrder.supplier_address}}</ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>

        <ion-card class="crdTable">
          <ion-grid>
            <ion-row class="tdOrderInfo">Төлбөр: </ion-row>
            <ion-row v-for="(priceData, index) in priceDatas" :key="index">
              <ion-col size-xs="9" size-sm="9" size-md="9" size-lg="9" size-xl="9">{{ priceData.name }}:</ion-col>
              <ion-col class="colinfo" size-xs="3" size-sm="3" size-md="3" size-lg="3" size-xl="3">{{ priceData.orderItem }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-text v-if="order.pay_status === 'Төлөөгүй'" color="danger" >{{ order.pay_status }}</ion-text >
                <ion-text v-if="order.pay_status === 'Төлсөн'" color="success" >{{ order.pay_status }}</ion-text >
              </ion-col>
            </ion-row>
            <ion-row v-if="order.order_status === 'Жолоочид хуваарилсан'">
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" > <ion-text color="success"> {{ order.order_status }}</ion-text> </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" >
                <ion-button size="small" expand="full" color="success" shape="round" @click="nextStep" >Төлөв ахиулах</ion-button>
              </ion-col>
            </ion-row>
            <ion-row v-if="order.order_status === 'Хүргэлтэнд гарсан'">
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" >
                <ion-text style="color: green; font-size: 18px">{{order.order_status}}</ion-text>
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
                <ion-button size="small" expand="full" color="success" shape="round" @click="nextStep" >Төлөв ахиулах</ion-button>
              </ion-col>
            </ion-row>
            <ion-row v-if="order.order_status === 'Очсон'">
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
                <ion-text style="color: green; font-size: 18px">{{order.order_status}}</ion-text>
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" >
                <ion-button size="small" expand="full" color="success" shape="round" @click="nextStep">
                  Төлөв ахиулах
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row v-if="order.order_status === 'Амжилттай'">
              <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" >
                <ion-text style="color: green; font-size: 18px">{{order.order_status}}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCard, IonGrid, IonCol, IonRow, IonText, IonImg, IonButton, loadingController, IonRefresher, IonRefresherContent,} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { GoogleMap, Marker } from "vue3-google-map";
import { getUser } from "../utils/auth";
import MdArrowBackIcon from "vue-ionicons/dist/md-arrow-back.vue";
import helper from "../helpers/helper";
import services from "../helpers/services";
export default defineComponent({
  name: "orderInfo",
  components: { IonContent, MdArrowBackIcon, GoogleMap, Marker, IonPage, IonCard, IonGrid, IonCol, IonRow, IonText, IonImg, IonButton, IonRefresher, IonRefresherContent,},
  setup() {
    const doRefresh = (event) => {
      window.location.reload()
      event.target.complete();
    }
    return { doRefresh }
  },
  async mounted() {
    await this.userData();
    this.getOrder(this.$router.currentRoute._rawValue.params.id);
  },
  methods: {
    async userData() {
      const response = await getUser();
      this.userName = response.username;
    },
    orderStatusText(status) {
      switch (status) {
        case 1:
          return "Жолоочид хуваарилсан";
        case 2:
          return "Хүргэлтэнд гарсан";
        case 3:
          return "Очсон";
        case 4:
          return "Амжилттай";
        default:
          break;
      }
    },
    nextStep() {
      if (this.order.order_status === "Жолоочид хуваарилсан") {
        this.driverOutByDriver();
      } else if (this.order.order_status === "Хүргэлтэнд гарсан") {
        this.driverInByDriver();
      } else if (this.order.order_status === "Очсон") {
        this.orderCompleteByDriver();
      }
    },
    driverOutByDriver() {
      const body = {
        order_id: this.order.id,
        user_name: this.userName,
      };

      services.driverOutByDriver(body).then((response) => {
        if (response.status === "success") {
          this.order = response.data;
          this.orderStatusText(
            response.data.order_status && response.data.order_status !== ""
              ? response.data.order_status
              : "Хүргэлтэнд гарсан"
          );
        }
      });
    },
    driverInByDriver() {
      const body = {
        order_id: this.order.id,
        user_name: this.userName,
      };
      services.driverInByDriver(body).then((response) => {
        if (response.status === "success") {
          this.order = response.data;
          this.orderStatusText(
            response.data.order_status && response.data.order_status !== ""
              ? response.data.order_status
              : "Очсон"
          );
        }
      });
    },
    orderCompleteByDriver() {
      const body = {
        order_id: this.order.id,
        user_name: this.userName,
      };
      services.orderCompleteByDriver(body).then((response) => {
        if (response.status === "success") {
          this.order = response.data;
          this.orderStatusText(
            response.data.order_status && response.data.order_status !== ""
              ? response.data.order_status
              : "Амжилттай"
          );
        }
      });
    },
    formatPrice(price) {
      return helper.formatPrice(price);
    },
    formatDate(val) {
      return helper.formatDate(val);
    },
    async getOrder(id) {
      const loading = await loadingController.create({
        message: "Уншиж байна...",
        duration: this.timeout,
      });
      await loading.present();
      const todos = (
        await axios.get(
          "https://api.toktokmall.mn/api/driver/getOneOrderByDriver?order_id=" +
            id +
            "&driver_name=" +
            this.userName
        )
      ).data;
      this.order = todos.data;
      this.location = {
        lat: parseFloat(this.order.user_lat),
        lng: parseFloat(this.order.user_lon),
      };
      this.productOrder = this.order.OrderItems;
      loading.dismiss();
      this.$forceUpdate();
    },
  },
  computed: {
    orderDatas() {
      const returnData = [
        { name: "Захиалсан цаг", orderItem: helper.formatDate(this.order.createdAt) },
        { name: "Хүргэгдэх ёстой цаг", orderItem: helper.formatDate(this.order.promised_time) },
        { name: "Захиалгын дугаар", orderItem: this.order.order_number },
        { name: "Хүргэх хаяг", orderItem: this.order.full_address },
        { name: "Албан бус хаяг", orderItem: this.order.un_official_address },
        { name: "Орц", orderItem: this.order.addr_orts },
        { name: "Орцны код", orderItem: this.order.addr_orts_code },
        { name: "Хаалга", orderItem: this.order.addr_haalga },
        { name: "Утас", orderItem: this.order.user_phone },
        { name: "Нэмэлт мэдээлэл", orderItem: this.order.addr_desc },
        { name: "Захиалгын тэмдэглэл", orderItem: this.order.note },
      ];
      return returnData;
    },
    // productDatas() {
    //   this.productOrder.forEach((el) => {
    //       this.brandName = el.brand_name
    //       this.variantName = el.variant_name
    //       this.itemCount = el.item_count
    //       this.menuPrice = el.menu_price
    //     })
    //     const returnData = [
    //       { name: "Брэнд", orderItem: this.brandName},
    //       { name: "Нэр", orderItem: this.variantName },
    //       { name: "Тоо", orderItem: helper.formatPrice(this.itemCount) },
    //       { name: "Үнэ", orderItem: helper.formatPrice(this.menuPrice) }
    //     ]
    //   return returnData;
    // },
    priceDatas() {
      const returnData = [
        { name: "Хүргэлтийн төлбөр", orderItem: helper.formatPrice(this.order.delivery_charge) },
        {
          name: "Хөнгөлөлт",
          orderItem: helper.formatPrice(this.order.delivery_charge),
        },
        {
          name: "Нийт дүн",
          orderItem: helper.formatPrice(this.order.pay_total),
        },
      ];
      return returnData;
    },
    
    isLogged() {
      return this.$store._state.data.isLogged;
    },
    // userData() {
    //   return this.$store._state.data.user;
    // },
    
  },
  data() {
    return { 
      userName: '',
      order: [], productOrder: [], activeStep: 1, location: { lat: 47.901168, lng: 106.878392 }, marker: true, brandName: null, variantName: null, itemCount: null, menuPrice: null, email: ''
    };
  },
  async created() {
    await this.userData();
    this.getOrder(this.$router.currentRoute._rawValue.params.id);
  },
});
</script>

<style scoped>
@media screen and (min-width: 1000px) {
  ion-col {
    font-size: 25px;
  }
  ion-img {
    margin-left: 15%;
    height: 90%;
    width: 50%;
  }
  .map {
    width: 100%;
    height: 600px;
  }
  .tdOrderInfo {
    font-size: 30px;
    font-weight: bold;
  }
  .crdTable {
    width: 80%;
    margin-left: 10%;
  }
  ion-button {
    font-size: 18px;
  }
}
@media screen and (min-width: 501px) and (max-width: 999px) {
  .map {
    width: 100%;
    height: 400px;
  }

  .tdOrderInfo {
    margin-left: 7%;
    font-size: 20px;
    font-weight: bold;
  }
  ion-button {
    font-size: 15px;
  }
}
@media screen and (max-width: 500px) {
  .map {
    width: 100%;
    height: 300px;
  }
  .tdOrderInfo {
    margin-left: 7%;
    font-size: 16px;
    font-weight: bold;
  }
}
.div {
  overflow-y: scroll;
  height: 93%;
  font-family: "Mulish-Regular";
}
.colinfo {
  font-weight: bold;
  direction: auto;
}
</style>