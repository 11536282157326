import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from '../views/Login.vue'
import {getUser} from '../utils/auth'
import Order from '../views/order/Order.vue'
import OrderInfo from '../views/OrderInfo.vue'
import SignUp from '../views/SignUp.vue'
import orderHistory from '../views/orderHistory.vue'
import Transfer from '../views/Transfer.vue'
import TransferInfo from '../views/TransferInfo.vue'
import location from '../views/location.vue'
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/location',
    name: 'Location',
    component: location
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: SignUp
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/orderInfo/:id',
    name: 'OrderInfo',
    component: OrderInfo
  },
  {
    path: '/orderHistory',
    name: 'OrderHistory',
    component: orderHistory
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer
  },
  {
    path: '/transferInfo/:id',
    name: 'TransferInfo',
    component: TransferInfo
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
    const user = await getUser()
    if (user===undefined && to.name !== 'Login') {
      if (to.name === 'signUp') next()
      else next('/login')
    } else if (user!==undefined){
      if (to.name === 'signUp' || to.name === 'Login') next('/order')
      else next()
    } else {
       next()
     }
})

export default router
