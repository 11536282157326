import * as dayjs from 'dayjs'

export default {
    formatDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
        if (date) {
            return dayjs(date).format(format)
        } else {
            return '-'
        }
    },
    formatPrice(data) {
        let val = (data / 1).toFixed(0).replace(".", "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDistanceFromLatLonInKm (lat1, lon1, lat2, lon2) {
    var R = 6371000
    var dLat = deg2rad(lat2 - lat1)
    var dLon = deg2rad(lon2 - lon1)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
    },
    
}
function deg2rad(deg) {
    return deg * (Math.PI/180)
}