<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-grid>
          <ion-row>
            <ion-col
              size-xs="10"
              offset-xs="1"
              size-sm="10"
              offset-sm="1"
              size-md="8"
              offset-md="2"
              size-lg="6"
              offset-lg="3"
            >
              <ion-img id="img" :src="require('../img/toktokmall.png')" />
              <ion-item style="margin-top: 30px">
                <ion-input
                  type="text"
                  autocomplete="off"
                  v-model="loginForm.username"
                  placeholder="Нэр"
                  aut
                ></ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.username === ''">Нэвтрэх нэрээ оруулна уу!</ion-text>
              <ion-item style="margin-top: 5px">
                <ion-input
                  inputmode="email"
                  autocomplete="off"
                  v-model="loginForm.email"
                  placeholder="Имэйл"
                  aut
                ></ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.email === ''">Имэйл-ээ оруулна уу!</ion-text>
              <ion-item style="margin-top: 5px">
                <ion-input
                  type="password"
                  autocomplete="off"
                  v-model="loginForm.password"
                  placeholder="Нууц үг"
                >
                </ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.password === ''">Нууц үгээ оруулна уу!</ion-text>
              <ion-item style="margin-top: 5px">
                <ion-input
                  autocomplete="off"
                  v-model="loginForm.nickname"
                  placeholder="nickname"
                  aut
                ></ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.nickname === ''">nickname оруулна уу!</ion-text>
              <ion-item style="margin-top: 5px">
                <ion-input
                  autocomplete="off"
                  v-model="loginForm.regNo"
                  min="10"
                  max="10"
                  placeholder="Регистрийн дугаар"
                  aut
                ></ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.regNo === ''">Регистрийн дугаараа оруулна уу!</ion-text>
              <ion-item style="margin-top: 5px">
                <ion-input
                  type="date"
                  v-model="loginForm.birthdate"
                  placeholder="Төрсөн огноо"
                  aut
                ></ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.birthdate === ''">Төрсөн он сар өдрөө оруулна уу!</ion-text>
              <ion-item style="margin-top: 5px">
                <ion-input
                  type="text"
                  v-model="loginForm.phone_number"
                  placeholder="Утас"
                  aut
                ></ion-input>
              </ion-item>
                <ion-text color="danger" v-if="loginForm.phone_number.length <= 4">Утасны дугаараа оруулна уу!</ion-text>
              <ion-button
                expand="block"
                style="margin-top: 20px"
                type="submit"
                @click="signUp()"
                color="success"
                :disabled="isDisable()"
                >Бүртгүүлэх</ion-button
              >
              <ion-button
                expand="block"
                style="margin-top: 20px"
                type="submit"
                @click="logClick()"
                color="primary"
                >Нэвтрэх</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonImg,
  IonInput,
  IonButton,
  toastController,
  IonCol,
  IonRow,
  IonGrid,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { signUp } from '../utils/auth'
// import { FirebaseAuthentication } from '@ionic-native/firebase-authentication'
export default defineComponent({
  name: "Login",
  data() {
    return {
      loginForm: {
        username: '',
        email: '',
        password: '',
        nickname: '',
        birthdate: '',
        phone_number: '+976',
        regNo: ''
      },
    };
  },
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    IonImg,
    IonCol,
    IonRow,
    IonGrid,
    IonItem,
  },

  methods: {
    isDisable() {
      let disable = false
      if (this.loginForm.username === "" || this.loginForm.email === "" || this.loginForm.password === "" || this.loginForm.nickname === "" || this.loginForm.birthdate === "" || this.loginForm.phone_number.length < 12 || this.loginForm.regNo === "") {
        disable = true
      } else {
        disable = false
      }
      return disable
    },
    logClick () {
      this.$router.push({path: 'login'})
    },
    async signUp() {
      try {
        this.loading = true
        signUp(this.loginForm.username, this.loginForm.email, this.loginForm.password, this.loginForm.nickname, this.loginForm.birthdate, this.loginForm.phone_number, this.loginForm.regNo).then(async (response) => {
          console.log(response)
          // await this.bitrixSignUpRequest()
          const toast = await toastController.create({
            header: "Амжилттай",
            duration: 2000,
            position: "bottom",
          });
          this.$router.push({path: 'login'})
          await toast.present();
          this.loading = false
        }).catch(async err=>{
          const toast = await toastController.create({
            header: "Алдаа",
            message: err.message,
            duration: 2000,
            position: "top",
          });
          await toast.present();
        })
      } catch (err) {
        const toast = await toastController.create({
          header: "Алдаа",
          message: err.message,
          duration: 2000,
          position: "top",
        });
        await toast.present();
      }
    },
    // async bitrixSignUpRequest() {
    //   try {
    //     const axios = require('axios')
    //     const body = {
    //       fields: {
    //         NAME: this.loginForm.username,
    //         OPENED: 'Y',
    //         TYPE_ID: 'CLIENT',
    //         UF_CRM_TOKTOK_TYPE: 'TOKTOKMALL-RIDER',
    //         PHOTO: {
    //           fileData: '',
    //         },
    //         PHONE: [
    //           {
    //             VALUE: this.loginForm.phone_number.split('+976')[1],
    //             VALUE_TYPE: 'HOME',
    //           },
    //         ],
    //         EMAIL: [
    //           {
    //             VALUE: this.loginForm.email,
    //             VALUE_TYPE: 'HOME',
    //           },
    //         ],
    //       },
    //     }
    //     const config = {
    //       method: 'post',
    //       url: `https://toktok.bitrix24.com/rest/1/8kahn13m7r0rtb4s/crm.contact.add`,
    //       data: body,
    //       headers: {
    //         Cookie: 'BITRIX_SM_SALE_UID=7; qmb=.',
    //       },
    //     }

    //     await axios(config)
    //       .then(function (response) {
    //         console.log(JSON.stringify(response.data))
    //       })
    //       .catch(function (error) {
    //         console.log(error)
    //       })
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
  },
});
</script>

<style>
@media screen and (min-width: 999px) {
  #img {
    margin-top: 20%;
    height: 30%;
  }
}
@media screen and (min-width: 600px) and (max-width: 990px) {
  #img {
    height: 45%;
  }
}
@media screen and (max-width: 550px) {
  #img {
    height: 40%;
    width: 100%;
  }
}
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
input:-webkit-autofill {
  background-color: #000 !important;
}
</style>