import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";


const store = createStore({
  state: {
    user: null,
    isLogged: false,
    userAddresses: [],
    closeModalDate: null
  },

  mutations: {
    closeModalDates(state, payload) {
      if (payload) {
        state.closeModalDate = payload
      }
    },
    setUserData(state, payload) {
      let isLogged = false
      if (payload) {
        isLogged = true
      }
      state.isLogged = isLogged
      state.user = payload;
    },

  },
  plugins: [createPersistedState()],
});
export default store