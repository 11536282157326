<template>
  <ion-page>
    <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        refreshing-spinner="circles"
        refreshing-text="Уншиж байна">
      </ion-refresher-content>
    </ion-refresher>
      <div>
        <ion-grid>
            <ion-row style="font-size:18px; font-family: 'Mulish-Regular';">
              <ion-col
                size-xs="4"
                size-sm="4"
                size-md="3"
                size-lg="5"
                size-xl="5"
              >
                <ion-text @click="$router.go(-1)"
                  ><MdArrowBackIcon />
                </ion-text>
              </ion-col>
              <ion-col
                size-xs="6"
                size-sm="6"
                size-md="7"
                size-lg="5"
                size-xl="5"
              >
                <ion-text>Татан авалт</ion-text>
              </ion-col>
              <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" >
                <ion-menu-button auto-hide="false" @click="openMenu()" menu="menu" style="color:green; font-size:25px;"></ion-menu-button>
              </ion-col>
            </ion-row>
          </ion-grid>
      </div>
      <div v-if="transfers.length > 0" class="div">
        <ion-card
          @click="pushTransferInfo(transfer)"
          v-for="transfer in transfers"
          :key="transfer"
        >
          <ion-grid>
            <ion-row>
              <ion-col
                size-xs="1"
                size-sm="1"
                size-md="1"
                size-lg="1"
                size-xl="1"
              >
                <MdHomeIcon class="icon" style="fill: green" />
              </ion-col>
              <ion-col
                class="name"
                size-xs="8"
                size-sm="8"
                size-md="11"
                size-lg="11"
                size-xl="11"
              >
                {{ transfer.transfer_name }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col
                size-xs="2.5"
                size-sm="2.5"
                size-md="2.5"
                size-lg="2.5"
                size-xl="2.5"
                style="font-weight: bold"
                class="transferID"
              >
                {{ transfer.transfer_id }}
              </ion-col>
              <ion-col
                size-xs="9.5"
                size-sm="9.5"
                size-md="9.5"
                size-lg="9.5"
                size-xl="9.5"
                class="date"
              >
                Хугацаа: {{ formatDate(transfer.expected_arrival_at) }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>

      <div v-else>
        <p class="text-center" style="opacity: 0.5; text-align: center">
          Татан авалт байхгүй байна
        </p>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCol,
  IonRow,
  IonCard,
  IonContent,
  IonPage,
  IonGrid,
  IonText,
  IonMenuButton,
  menuController,
} from "@ionic/vue";
import MdHomeIcon from "vue-ionicons/dist/md-home.vue";
import { defineComponent } from "vue";
import axios from "axios";
import { getUser } from '../utils/auth';
// import { getUserName } from '../utils/auth'
import helper from "../helpers/helper";
import MdArrowBackIcon from "vue-ionicons/dist/md-arrow-back.vue";
export default defineComponent({
  components: {
    IonCol,
    IonRow,
    IonCard,
    IonContent,
    IonPage,
    IonGrid,
    IonText,
    IonMenuButton,
    MdArrowBackIcon,
    MdHomeIcon,
  },
  data () {
    return {
      userName: '',
      transfers: [],
      loading: false,
      errorArray: [],
      email: ''
    }
  },
  setup() {
    const doRefresh = (event) => {
      window.location.reload()
      event.target.complete();
    }
    return { doRefresh }
  },
  methods: {
    async userData() {
      const response = await getUser();
      this.userName = response.username;
    },
    openMenu() {
      menuController.open("app-menu");
    },
    formatDate(val) {
      return helper.formatDate(val);
    },

    async getTransfer() {
      const todos = (
        await axios.get(
          "https://api.toktokmall.mn/api/driver/getSuggestedTransfersByDriverId?driver_uid=" +
            this.userName
        )
      ).data;
      this.transfers = todos.data;
      this.$forceUpdate();
    },
    pushTransferInfo(transfer) {
      this.$router.push({
        name: "TransferInfo",
        params: { id: transfer.transfer_id },
      });
    },
    // async saveTransfer () {
    //   this.loading = true
    //   const sendBody = {
    //     transfer_name: this.transfers.transfer_name,
    //     transfer_id: this.transfers.transfer_id,
    //     user_name: ''
    //   }
    //   sendBody.user_name = await getUserName()
    //   this.errorArray = []
    //   if (this.transfers.length === 0) {
    //     this.errorArray.push(
    //       {
    //         key: '',
    //         value: 'Татан авалт байхгүй байна.'
    //       }
    //     )
    //   }
    // }
  },
  computed: {
    isLogged() {
      return this.$store._state.data.isLogged;
    },
    // userData() {
    //   return this.$store._state.data.user;
    // },
  },
  async mounted() {
    await this.userData()
    await this.getTransfer();
  },
  async created() {
    await this.userData()
    await this.getTransfer();
  },
});
</script>
<style scoped>
@media screen and (min-width: 1000px) {
  ion-col {
    font-size: 15px;
  }
  .header {
    font-size: 22px;
  }
  .div {
    width: 80%;
    margin-left: 10%;
  }
  .icon {
    font-size: 35px;
  }
  .name {
    font-size: 35px;
  }
  .transferID {
    font-size: 20px;
  }
  .date {
    font-size: 20px;
  }
}
@media screen and (min-width: 501px) and (max-width: 999px) {
  ion-col {
    font-size: 15px;
  }
  .header {
    font-size: 22px;
  }
  .icon {
    font-size: 26px;
  }
  .name {
    font-size: 26px;
  }
  .transferID {
    font-size: 16px;
  }
  .date {
    font-size: 16px;
  }
}
</style>