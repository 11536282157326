<template>
  <ion-page>
    <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        refreshing-spinner="circles"
        refreshing-text="Уншиж байна">
      </ion-refresher-content>
    </ion-refresher>
      <div>
        <ion-grid>
            <ion-row>
              <ion-col
                size-xs="2"
                size-sm="2"
                size-md="3"
                size-lg="4"
                size-xl="4"
              >
                <ion-text class="header" @click="$router.go(-1)"
                  ><MdArrowBackIcon />
                </ion-text>
              </ion-col>
              <ion-col
                size-xs="10"
                size-sm="10"
                size-md="7"
                size-lg="6"
                size-xl="6"
              >
                <ion-text class="header">Татан авалтын бүтээгдэхүүнүүд</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
      </div>
      <div class="div" v-if="transferInformation">
        <ion-card class="crdTable">
          <ion-grid>
            <ion-row>
              <ion-col v-if="marker === 1" class="map"
                ><GoogleMap
                  api-key="AIzaSyA53ydQHJU3UyAPwxJ8RqQgAECe8dqyTyA"
                  style="width: 100%; height: 100%"
                  :center="supplierLocation"
                  :loc="warehouseLocation"
                  :zoom="15"
                >
                  <Marker :options="{ position: supplierLocation }" />
                  <Marker
                    :options="{ position: warehouseLocation }"
                  /> </GoogleMap
              ></ion-col>

              <ion-col v-if="marker === 2" class="map"
                ><GoogleMap
                  api-key="AIzaSyA53ydQHJU3UyAPwxJ8RqQgAECe8dqyTyA"
                  style="width: 100%; height: 100%"
                  :center="supplierLocation"
                  :loc="warehouseLocation"
                  :zoom="15"
                >
                  <Marker :options="{ position: supplierLocation }" />
                  <Marker
                    :options="{ position: warehouseLocation }"
                  /> </GoogleMap
              ></ion-col>
            </ion-row>
            <ion-row class="tdOrderInfo">
             <ion-col 
                  size-xs="6"
                  size-sm="6"
                  size-md="6"
                  size-lg="6"
                  size-xl="6"> 
              <ion-button
                v-on:click="marker = 1"
                color="warning"
                expand="block"
                shape="round"
                size="small"
                ><ion-text style="color:white;">Харилцагч</ion-text></ion-button
              ></ion-col>
              <ion-col 
                  size-xs="6"
                  size-sm="6"
                  size-md="6"
                  size-lg="6"
                  size-xl="6">
              <ion-button
                v-on:click="marker = 2"
                color="success"
                expand="block"
                size="small"
                shape="round"
                >Агуулах</ion-button
              ></ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
        <ion-text class="product-class" style="margin-left:5%;">Бүтээгдэхүүнүүд:</ion-text>
        <ion-card class="card"
          v-for="product in transferInformation.products" :key="product">
          <ion-grid>
            <ion-row>
              <ion-item>
                <ion-col
                  size-xs="5"
                  size-sm="5"
                  size-md="5"
                  size-lg="5"
                  size-xl="5"
                >
                  <ion-img class="img" :src="product.product_image"> </ion-img>
                </ion-col>
                <ion-col
                  size-xs="7"
                  size-sm="7"
                  size-md="7"
                  size-lg="7"
                  size-xl="7"
                >
                  <ion-row>
                    <ion-col>
                      <ion-text class="productName-class">{{ product.product_name }}</ion-text>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-item>
            </ion-row>
          </ion-grid>

         <ion-grid
            v-for="variant in product.variants"
            :key="variant"
            style="padding-left: 8%; margin-left:20%;"
          >
            
              <ion-row >
                <ion-col v-if="variant.variant_name !== 'default'"
                  size-xs="7"
                  size-sm="7"
                  size-md="7"
                  size-lg="5"
                  size-xl="5"
                  ><ion-text >{{ variant.variant_name }}</ion-text></ion-col
                >
                <ion-col v-if="variant.variant_name === 'default'"
                  size-xs="7"
                  size-sm="7"
                  size-md="7"
                  size-lg="5"
                  size-xl="5"
                  ></ion-col
                >
                <ion-col
                  size-xs="5"
                  size-sm="5"
                  size-md="5"
                  size-lg="5"
                  size-xl="5"
                >
                  <ion-text class="transferNumber">
                    {{ variant.number_tobe_transferred }} ширхэг
                  </ion-text></ion-col
                >
              </ion-row>           
          </ion-grid>
        </ion-card>
      </div>
      <div class="footer">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button @click="btnComplete" color="success" expand="full" size="small" shape="round">
                Харилцагчид хүргэж өгсөн
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
  IonContent,
  IonText,
  IonImg,
  menuController,
  IonButton,
  IonItem,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { getUserName } from '../utils/auth';
import helper from "../helpers/helper";
import { GoogleMap, Marker } from "vue3-google-map";
import MdArrowBackIcon from "vue-ionicons/dist/md-arrow-back.vue";
export default defineComponent({
  components: {
    IonCard,
    IonGrid,
    IonCol,
    IonRow,
    IonPage,
    IonContent,
    MdArrowBackIcon,
    IonText,
    IonImg,
    GoogleMap,
    Marker,
    IonButton,
    IonItem,
    
  },
  setup() {
    const doRefresh = (event) => {
      window.location.reload()
      event.target.complete();
    }
    return { doRefresh }
  },
  methods: {
    btnComplete() {},
    openMenu() {
      menuController.open("app-menu");
    },
    userData () {
      this.loading = true
      getUserName(this.email).then((response) => {
        console.log(response)
        this.loading = false
      })
    },
    formatPrice(price) {
      return helper.formatPrice(price);
    },
    formatDate(val) {
      return helper.formatDate(val);
    },
    async getTransfer(id) {
      const loading = await loadingController.create({
        message: "Уншиж байна...",
        duration: this.timeout,
      });
      await loading.present();
      const todos = await axios.get(
        "https://api.toktokmall.mn/api/driver/getTransferByTransferId?transfer_id=" +
          id
      );
      this.transferInformation = todos.data.data;
      
      this.supplierLocation = {
        lat: parseFloat(
          this.transferInformation.supplier.location.split(",")[0]
        ),
        lng: parseFloat(
          this.transferInformation.supplier.location.split(",")[1]
        ),
      };

      this.warehouseLocation = {
        lat: parseFloat(
          this.transferInformation.warehouse.location.split(",")[0]
        ),
        lng: parseFloat(
          this.transferInformation.warehouse.location.split(",")[1]
        ),
      };
      loading.dismiss();
      this.$forceUpdate();
    },
  },
  computed: {
    isLogged() {
      return this.$store._state.data.isLogged;
    },
    // userData() {
    //   return this.$store._state.data.user;
    // },
  },
  data() {
    return {
      center: {
        lat: 47.901274,
        lng: 106.957278,
      },
      email: '',
      transferInformation: null,
      supplierLocation: { lat: 47.901274, lng: 106.957278 },
      warehouseLocation: { lat: 47.901274, lng: 106.957278 },
      marker: 1,
    };
  },
  created() {
    this.getTransfer(this.$router.currentRoute._rawValue.params.id);
    this.userData()
  },
});
</script>
<style scoped>
@media screen and (min-width: 1000px) {
  .transferNumber{
    font-size: 18px;
    color: red;
  }
  .productName-class{
    font-size: 15px;
  }
  .header{
    font-size: 22px;
  }
  .product-class{
    font-size: 35px;
  }
  .map {
    width: 100%;
    height: 600px;
  }
  .card{
    width: 70%;
    margin-left: 20%;
  }
  .img{
    width: 40%;
  }
}
@media screen and (min-width: 560px) and (max-width: 999px) {
  .header{
    font-size: 20px;
  }
  .map {
    width: 100%;
    height: 400px;
  }
  .transferNumber{
    font-size: 18px;
    color: red;
  }
}
@media screen and (max-width: 550px){
  .header{
    font-size: 18px;
  }
  .map {
    width: 100%;
    height: 200px;
  }
  .transferNumber{
    font-size: 14px;
    color: red;
  }
  .productName-class{
    font-size: 14px;
  }
}
.div {
  overflow-y: scroll;
  height: 87%;
}
.footer{
  font-size: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>