<template>
  <ion-page>
    <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        refreshing-spinner="circles"
        refreshing-text="Уншиж байна">
      </ion-refresher-content>
    </ion-refresher>
      <div>
        <ion-grid>
          <ion-row style="font-size: 18px; font-family: 'Mulish-Regular'">      
            <ion-col
              size-xs="3"
              size-sm="3"
              size-md="3"
              size-lg="5"
              size-xl="5"
            >
              <ion-text @click="$router.go(-1)"><MdArrowBackIcon /> </ion-text>
            </ion-col>
            <ion-col
              size-xs="7"
              size-sm="7"
              size-md="7"
              size-lg="5"
              size-xl="5"
            >
              <ion-text>Хүргэлтийн түүх</ion-text>
            </ion-col>
            <ion-col
              size-xs="2"
              size-sm="2"
              size-md="2"
              size-lg="2"
              size-xl="2"
            >
              <ion-menu-button
                auto-hide="false"
                @click="openMenu()"
                menu="menu"
                style="color: green; font-size: 25px"
              ></ion-menu-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <ion-card>
        <ion-grid>
          <ion-row class="ion-text-center">
            <ion-col size="1.8">
              <ion-button class="button" v-on:click="isHidden = 1" fill="clear" size="small" >Бүгд</ion-button >
            </ion-col>
            <ion-col size="2.8">
              <ion-button class="button" v-on:click="isHidden = 2" fill="clear" size="small" >Өнөөдөр</ion-button >
            </ion-col>
            <ion-col size="2.8"> 
              <ion-button class="button" v-on:click="isHidden = 3" fill="clear" size="small" >Өчигдөр</ion-button>
            </ion-col>
            <ion-col size="2.5"> 
              <ion-button class="button" v-on:click="isHidden = 4" fill="clear" size="small">7 хоног</ion-button>
            </ion-col>
            <ion-col size="2"> 
              <ion-button class="button" v-on:click="isHidden = 5" fill="clear" size="small">Сар</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
      <ion-card>
        <ion-grid>
          <ion-row v-if="orderHistory">
            <ion-col size-xs="7" size-sm="7" size-md="7" size-lg="7" size-xl="7" >Нийт хүргэлт:</ion-col>
            <ion-col v-if="isHidden === 1" size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" >{{ orderHistory.total }}</ion-col>
            <ion-col v-else size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" >
              <ion-row v-for="x in orderHistoryData" :key="x">
                <ion-col v-if="isHidden === 2" size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" >
                  {{ x.Today.order.count }}
                </ion-col>
                <ion-col v-if="isHidden === 3" size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" >
                  {{ x.Yesterday.order.count }}
                </ion-col>
                <ion-col v-if="isHidden === 4" size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" >
                  {{ x.Week.order.count }}
                </ion-col>
                <ion-col v-if="isHidden === 5" size-xs="5" size-sm="5" size-md="5" size-lg="5" size-xl="5" >
                  {{ x.Month.order.count }}
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row v-else-if="orderHistory.length < 0">
            <ion-text>Танд хийсэн хүргэлт байхгүй байна</ion-text>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
  IonContent,
  IonButton,
  IonText,
  IonMenuButton,
  menuController,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { getUser } from '../utils/auth';
import axios from "axios";
import helper from "../helpers/helper";
import MdArrowBackIcon from "vue-ionicons/dist/md-arrow-back.vue";
export default defineComponent({
  components: {
    IonCard,
    IonGrid,
    IonCol,
    IonRow,
    IonPage,
    IonContent,
    IonButton,
    IonMenuButton,
    MdArrowBackIcon,
    IonText,
  },
  setup() {
    const doRefresh = (event) => {
      window.location.reload()
      event.target.complete();
    }
    return { doRefresh }
  },
  async mounted() {
    await this.userData()
    await this.getOrders()
  },
  async created() {
    await this.userData()
    await this.getOrder();
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    formatPrice(price) {
      return helper.formatPrice(price);
    },
    formatDate(val) {
      return helper.formatDate(val);
    },
    async getOrder() {
      const loading = await loadingController.create({
        message: "Уншиж байна...",
        duration: this.timeout,
      }, 2000);
      await loading.present();
      const orders = (
        await axios.get(
          "https://api.toktokmall.mn/api/driver/getDriverHistory?driver_name=" +
            this.userName
        )
      ).data;
      this.orderHistory = orders;
      this.orderHistoryData = this.orderHistory.data;
      loading.dismiss();
      this.$forceUpdate();
    },
    async userData () {
      const response = await getUser()
      this.userName = response.username
    },
  },
  computed: {
    isLogged() {
      return this.$store._state.data.isLogged;
    },
  },
  data() {
    return { 
      userName: '',
      orderHistory: [], orderHistoryData: [], isHidden: 1, email: '' };
  },
});
</script>
<style scoped>
.button{
  color: black; 
  font-weight: bold;
}
</style>